import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from './redux/store'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Anchor } from 'ual-anchor'
import { Scatter } from 'ual-scatter'
import { UALProvider } from 'ual-reactjs-renderer'

const exampleNet = {
    chainId: process.env.REACT_APP_CHAIN_ID || 'aca376f206b8fc25a6ed44dbdc66547c36c6c33e3a119ffbeaef943642f0e906',
    rpcEndpoints: [{
      protocol: 'https',
      host: process.env.REACT_APP_EOS_HOST || 'eos.greymass.com',
      port: Number(443),
    }]
  }
const appName = "EOS Gift Card"
//const appName = "EGC" //to fix local Scatter
const anchor = new Anchor([exampleNet], { appName })
const scatter = new Scatter([exampleNet], { appName })

ReactDOM.render(<UALProvider chains={[exampleNet]} authenticators={[anchor, scatter]} appName={appName}><Provider store={store}><App /></Provider></UALProvider>, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()