import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Redirect, Switch, Link } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
import Home from './components/Home'
import Test from './components/Test'
import { withUAL } from 'ual-reactjs-renderer'

type AppProps = {
  ual: any
}

class App extends Component<AppProps> {
  render() {
    const { ual: { activeUser, activeAuthenticator } } = this.props
    //TODO: check activeAuthenticator & activeAuthenticator.users for rpc and permission
    const scatterAccount = activeUser?.scatter?.identity?.accounts?.find((x: any) => x.blockchain === 'eos')
    const accountName = activeUser?.accountName
    const permission = activeUser?.requestPermission || scatterAccount?.authority
    let account:any = undefined
    let wallet:any = undefined
    if (activeUser && activeAuthenticator) {
      account = { name: accountName, authority: permission, scatter: !!scatterAccount }
      wallet = { eosApi: activeUser }
    }

    const errorPage = <div className="text-center">
      <br/><br/><br/><br/>
      <h1>404</h1>
      <h3>Page not found.</h3>
      <br/>
      <Link to="/">zurück</Link>
    </div>

    return (
      <Router>
        <Footer>
          <Header account={account}/>
          <Switch>
            <Redirect from="/index.html" to="/" />
            <Route exact path="/" component={() => <Home account={account} wallet={wallet}/>}/>
            <Route exact path="/testing" component={() => <Test/>}/>
            <Route component={() => errorPage}/>
          </Switch>
        </Footer>
      </Router>
    )
  }
}

export default withUAL(App)
