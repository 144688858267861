import { FETCH_EOSUSD } from '../actionTypes'
import { SUCCESS, ERROR } from '../../constants'

const initialState = {
  eosUSD: 0,
  eosUSDLoading: true,
  eosUSDError: false
}

export default function(state = initialState, action: { type: string, status?: boolean, payload?: any }) {
  switch (action.type) {
    case FETCH_EOSUSD: {
      if(action.status === SUCCESS) {
        const { pairs, datapoints } = action.payload
        const quoted_precision = pairs?.rows[0]?.quoted_precision || 4
        const value = datapoints?.rows[0]?.median/10**quoted_precision
        return {
          ...state,
          eosUSD: value,
          eosUSDError: false,
          eosUSDLoading: false
        }
      } else if(action.status === ERROR) {
        return {
          ...state,
          eosUSDError: action.payload,
          eosUSDLoading: false
        }
      } else {
        return { ...state, eosUSDError: false, eosUSDLoading: true }
      }
    }
    default:
      return state
  }
}
