import { FETCH_EOSUSD } from './actionTypes'
import { SUCCESS, ERROR } from '../constants'
import { JsonRpc } from 'eosjs'

const rpc = new JsonRpc('https://'+(process.env.REACT_APP_EOS_HOST || 'eos.greymass.com'))

const fetchUSD = (status?: boolean, payload?: any) => ({
    type: FETCH_EOSUSD,
    status,
    payload
})

export const fetchEOSUSD = () => {
    return async(dispatch: Function) => {
        dispatch(fetchUSD())
        try {
            const eosUSD1 = await rpc.get_table_rows({
                json: true,               // Get the response as json
                code: 'delphioracle',      // Contract that we target
                scope: 'delphioracle',         // Account that owns the data
                table: 'pairs',        // Table name
                lower_bound: 'eosusd', // Primary key
                limit: 1,                // Maximum number of rows that we want to get
                reverse: false,           // Optional: Get reversed data
                show_payer: true          // Optional: Show ram payer
              });
              const eosUSD2 = await rpc.get_table_rows({
                json: true,               // Get the response as json
                code: 'delphioracle',      // Contract that we target
                scope: 'eosusd',         // Account that owns the data
                table: 'datapoints',        // Table name
                limit: 10,                // Maximum number of rows that we want to get
                reverse: false,           // Optional: Get reversed data
                show_payer: false          // Optional: Show ram payer
              });
            dispatch(fetchUSD(SUCCESS, { pairs: eosUSD1, datapoints: eosUSD2 }))
        } catch(err) {
            dispatch(fetchUSD(ERROR, err))
        }
    }
  }