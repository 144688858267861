import React, {Component} from 'react'
import './Home.css'
import { PrivateKey } from 'eosjs-ecc'
//import QRious from 'qrious'
//import { Modal } from 'react-bootstrap'
//import { Abi } from 'eosjs/dist/eosjs-rpc-interfaces'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { generatePDF } from '../pdfGenerate'
import scatter from '../../img/scatter.png'
import anchor from '../../img/anchor.png'
import imgHead from '../../img/head.png'
import imgTls from '../../img/tls.png'
import lukestokes from '../../img/lukestokes.png'
import cryptojake from '../../img/cryptojake.jpeg'
import stablecoin from '../../img/stablecoin.png'

import { connect } from 'react-redux'
import { fetchEOSUSD } from '../../redux/actions'

import { withUAL } from 'ual-reactjs-renderer'

type HomeProps = {
  login: any,
  wallet?: any,
  account?: { name: string, authority: any },
  fetchEOSUSD: Function,
  eosUSD: number,
  usdLoading: boolean,
  usdError: any,
  ual: any
}
type HomeState = {
  accountAvailable: any,
  accountName: string,
  showModal: boolean,
  error: any,
  transactionId: any,
  ownerPub: any,
  ownerPriv: any,
  activePub: any,
  activePriv: any,
  priceKiB: number
}

//TODO: pudding/drops background below header
class Home extends Component<HomeProps, HomeState> {
  constructor (props: HomeProps) {
    super(props)
    this.state = {
      accountAvailable: null,
      accountName: '',
      showModal: false,
      error: null,
      transactionId: null,
      ownerPub: null,
      ownerPriv: null,
      activePub: null,
      activePriv: null,
      priceKiB: 0.0001 //default value
    }
    this.props.fetchEOSUSD()
  }

  handleClose() {
    if(this.state.showModal !== false) this.setState({ showModal: false })
  }

  async checkAccount(e: any) {
    const val = e.target.value.toLowerCase()
    this.setState({ accountName: val })
    //console.log('check', val)
    if(val.length === 12) {
      if(!/^([a-z1-5]{12})$/.test(val)) {
        this.setState({ accountAvailable: 'The account name can only contain the letters a-z and digits 1-5!' })
        return false
      }
      const api = this.props.wallet.eosApi
      //console.debug('API', api)
      try {
        const account = await api.rpc.get_account(val)
        //console.debug('ACC', account)
        if(account.account_name === val) {
          console.info(`Account name ${val} is already taken:`, account)
          this.setState({ accountAvailable: false })
        } else {
          console.error('Cannot check Account availability...', account)
          this.setState({ accountAvailable: null })
        }
      } catch(err) {
        //console.debug('checkAccount', err)
        if(err.message.startsWith('unknown key')) {
          //ERROR: unspecified/unknown key => account could not be found and is thus still available
          console.info(`YAY! Account name ${val} is still available!`)
          this.setState({ accountAvailable: true })
        } else {
          console.error('Cannot check Account availability...', err)
          this.setState({ accountAvailable: null })
        }
      }
    } else {
      this.setState({ accountAvailable: null })
    }
  }

  async buyNow(amount: number, plan: string) {
    let privKey = await PrivateKey.randomKey()
    let activePriv = privKey.toWif()
    let activePub = PrivateKey.fromWif(activePriv).toPublic().toString()
    activePriv = activePriv.toString()

    let privKey2 = await PrivateKey.randomKey()
    let ownerPriv = privKey2.toWif()
    let ownerPub = PrivateKey.fromWif(ownerPriv).toPublic().toString()
    ownerPriv = ownerPriv.toString()

    this.setState({ activePriv, activePub, ownerPriv, ownerPub })

    const acc = {
      name: this.state.accountName,
      activeKey: {
        public: activePub,
        private: activePriv
      },
      ownerKey: {
        public: ownerPub,
        private: ownerPriv
      }
    }

    // ----------------------------
    // Now that we have an identity,
    // an EOSIO account, and a reference
    // to an eosjs object we can send a transaction.
    // ----------------------------
    // Never assume the account's permission/authority. Always take it from the returned account.
    const amnt = `${amount.toFixed(4)} EOS`
    const account = this.props.account
    const api = this.props.wallet.eosApi
    //console.debug('API', api)

    try {
      const memo = plan+':'+this.state.accountName+':'+acc.ownerKey.public+':'+acc.activeKey.public
      const trx = await api.signTransaction({
        actions: [{
          account: 'eosio.token',
          name: 'transfer',
          authorization: [{
            actor: account?.name,
            permission: account?.authority
          }],
          data: {
            from: account?.name,
            to: 'cointreasury',
            quantity: amnt,
            memo
          }
        }]
      }, {
        broadcast: true,
        blocksBehind: 3,
        expireSeconds: 120
      })
      if(trx.error) {
        this.setState({ transactionId: null, error: trx.error.message })
      } else {
        generatePDF(acc)
        console.info(`Transaction ID: ${trx.transactionId}`)
        this.setState({ transactionId: trx.transactionId, error: null })
      }
    } catch(err) {
      let error = err
      if(typeof error === 'string') {
        try {
          error = JSON.parse(error)
        } catch(e) {
          error = { message: null }
        }
      }
      console.error('buyNow:', err || String(err))
      this.setState({
        error: (error.error && error.error.details && error.error.details[0] && error.error.details[0].message) || error.message || err,
        transactionId: null
      })
    }

    this.setState({ showModal: true })
  }

  async componentWillMount() {
    //TODO: move into redux/generic
    const host = process.env.REACT_APP_EOS_HOST || 'eos.greymass.com'
    const res = await fetch(`https://${host}/v1/chain/get_table_rows`, {
      method: 'POST',
      body: JSON.stringify({
        scope: 'eosio',
        code: 'eosio',
        table: 'rammarket',
        json: true
      })
    })
    // https://github.com/EOSIO/eos/issues/4958
    const json = await res.json()
    const quote = json.rows[0].quote
    const base = json.rows[0].base
    const qb = parseInt(quote.balance.substring(0, quote.balance.length - 4), 10)
    const bb = parseFloat(base.balance.substring(0, base.balance.length - 4))
    const price_kib = qb/bb
    this.setState({ priceKiB: price_kib })
  }

  render() {
    const { ual: { showModal: login } } = this.props
    let modal = <Modal isOpen={this.state.showModal} toggle={this.handleClose.bind(this)}centered={true} size="lg" backdrop="static">
    <ModalHeader toggle={this.handleClose.bind(this)}>Receipt</ModalHeader>
    <ModalBody>
      <div className="text-center">
        {
          (this.state.error)?
          <span style={{color:'red'}}><strong>ERROR</strong><br/>{JSON.stringify(this.state.error)}<br/><br/></span> :
          <span></span>
        }
        {
          (this.state.transactionId)?
          <span><strong>View transaction on bloks.io</strong><br/><a href={`https://bloks.io/transaction/${this.state.transactionId}`} target="_blank" rel="noopener noreferrer" style={{wordBreak:'break-all'}}>{this.state.transactionId}</a><br/><br/></span> :
          <span></span>
        }
<div className="card card-body text-left" style={{background:'#e4e4e4'}}><pre style={{marginBottom:0, whiteSpace: 'pre-wrap', wordBreak:'break-all'}}>
account_name: {this.state.accountName}<br/>
<br/>
@active key:<br/>
{JSON.stringify(this.state.activePub)}<br/>
{JSON.stringify(this.state.activePriv)}<br/>
<br/>
@owner key:<br/>
{JSON.stringify(this.state.ownerPub)}<br/>
{JSON.stringify(this.state.ownerPriv)}
</pre></div>
{
  (this.state.transactionId)?
  <div>
    <br/>
    <strong>Make sure to <button className="btn btn-outline-primary" onClick={generatePDF.bind(this, {
      name: this.state.accountName,
      activeKey: {
        public: this.state.activePub,
        private: this.state.activePriv
      },
      ownerKey: {
        public: this.state.ownerPub,
        private: this.state.ownerPriv
      }
    })}>save the PDF</button> and this receipt!<br/>We cannot recover the account, if you loose the keys.</strong>
  </div>:
  <div></div>
}
      </div>
    </ModalBody>
    <ModalFooter>
      <Button color="danger" onClick={this.handleClose.bind(this)}>Close</Button>
    </ModalFooter>
  </Modal>

    return (<div className="Home">
    <section className="primary">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1>Give EOS to somebody you love!</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 order-md-2 text-center">
            <img src={imgHead} alt="eosgiftcard.com" style={{maxWidth:'100%'}}/>
            <br/>
            <img src="/poweredby.svg" alt="EOSIO" style={{width:'60%', margin:'20px 0px'}}/>
          </div>
          <div className="col-md-8 order-md-1">
            <p className="text-justify">
            <span className="highlight">EOS Gift Card</span> is a service, which allows you to easily create <strong>new EOS accounts</strong> for friends, family or co-workers in form of downloadable PDF files, e.g. as a <span className="highlight">birthday present</span> or to introduce somebody you love to the blockchain ecosystem. The accounts are <strong>fully pre-configured</strong> with the required <span className="highlight">CPU, NET and RAM</span> resources!
            <br/><br/>
            A <span className="highlight">new EOS account</span> must be created by another EOS user, who needs to stake CPU/NET resources and pay for the new account's initial RAM storage. This can take lots of time and is a very technical process. <strong>EOS Gift Card</strong> allows you to quickly create a new account with just the click of a button. It will generate a <span className="highlight">PDF file</span> for you, containing all relevant information like access keys, trusted wallets, popular dApps and helps with <span className="highlight">getting started</span> with EOS.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
          </div>
          <div className="col-md-12">Supported by</div>
          <div className="col-md-3 ref text-center text-md-left">
            <a href="https://www.stateofthedapps.com/dapps/eos-gift-card" target="_blank" rel="noopener noreferrer"><img src="/ref/stateofthedapps.png" alt="State of the Dapps"/></a>
          </div>
          <div className="col-md-3 ref text-center">
            <a href="https://eosdac.io" target="_blank" rel="noopener noreferrer"><img src="/ref/eosdac.svg" alt="eosDAC"/></a>
          </div>
          <div className="col-md-3 ref text-center">
            <a href="https://greymass.com" target="_blank" rel="noopener noreferrer"><img src="/ref/greymassfuel.png" alt="Greymass"/></a>
          </div>
          <div className="col-md-3 ref text-center text-md-right">
            <a href="https://dappradar.com/eos/1013/eos-gift-card" target="_blank" rel="noopener noreferrer"><img src="/ref/dappradar.png" alt="DappRadar"/></a>
          </div>
        </div>
      </div>
    </section>

    <section className="dark" id="about">
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-justify">
            <h1 className="text-center">About</h1>
            To use the <span className="highlight dark">EOS blockchain</span>, one needs an on-chain account, which is identified by a unique <span className="highlight dark">12 letter name</span> and connects the identity to the cryptographic keys, used for securely signing transactions on the blockchain.
            <br/><br/>
            The PDF generated by <strong>EOS Gift Card</strong> makes a <span className="highlight dark">perfect gift</span> or birthday present for a friend, who likes to explore the blockchain! And it takes only a couple of seconds to purchase this document below. So if you have a friend, who is interested in EOS, or blockchain in general, do him the favour of <strong>creating an EOS account</strong> for him, so he can explore the the <span className="highlight dark">endless possibilities</span> of the blockchain ecosystem.
          </div>
        </div>
        <div className="row communityReview">
        <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <blockquote>
                  <img src={lukestokes} alt="Luke Stokes" className="rounded-circle float-right ml-1"/>
                  <p className="mb-0">"Cool project created by <i>@slyon23</i> who helped build the eosDAC website. You can create EOS accounts as gifts for your friends: <a href="https://eosgiftcard.com">eosgiftcard.com</a>"</p>
                  <footer className="blockquote-footer">Luke Stokes via <cite title="twitter.com"><a href="https://twitter.com/lukestokes/status/1123289171819466752" target="_blank" rel="noopener noreferrer">twitter.com</a></cite></footer>
                </blockquote>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <blockquote>
                  <img src={stablecoin} alt="Stablecoin" className="rounded-circle float-right ml-1"/>
                  <p className="mb-0">"Hey that's really cool [...] looks like an easy idea to share EOS with a friend."</p>
                  <footer className="blockquote-footer">stablecoin via <cite title="reddit.com"><a href="https://www.reddit.com/r/eos/comments/b83kcr/introducing_my_first_dapp/" target="_blank" rel="noopener noreferrer">reddit.com</a></cite></footer>
                </blockquote>
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <blockquote>
                  <img src={cryptojake} alt="Cryptojake" className="rounded-circle float-right ml-1"/>
                  <p className="mb-0">"EOS [...] might represent a challenge for the less tech savvy or utterly lazy. Consider using <a href="https://eosgiftcard.com">eosgiftcard.com</a> to create an account you can forward"</p>
                  <footer className="blockquote-footer">cryptojake via <cite title="steemit.com"><a href="https://steemit.com/technology/@cryptojake/sense-chat-decentralized-chat-built-on-the-eos-blockchain-why-you-should-ditch-whatsapp-and-get-on-board" target="_blank" rel="noopener noreferrer">steemit.com</a></cite></footer>
                </blockquote>
              </div>
            </div>
          </div>

          {/*
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <blockquote>
                  <p className="mb-0">"Sometimes you see a new idea and go… why didn’t I think of that?… very nice option, thanks."</p>
                  <footer className="blockquote-footer">Peter S via <cite title="trybe.one"><a href="https://trybe.one/eos-gift-card-as-birthday-present/" target="_blank" rel="noopener noreferrer">trybe.one</a></cite></footer>
                </blockquote>
              </div>
            </div>
          </div>
          */}
        </div>
      </div>
    </section>


    <section className="primary">
      <div className="container">
        <div className="row">
          <div className="col-md-8 order-md-2">
            <h4>How does it work?</h4>
            <ol>
              <li>Choose an unique <strong>EOS account</strong> name (12 characters: a-z & 1-5)</li>
              <li>Choose an appropriate plan, which fits the needs of your friend</li>
              <li>Press the <strong>"Buy Now"</strong> button</li>
              <li>Sign the transaction, using your existing EOS account</li>
              <li>Hand the generated <strong>PDF file</strong> over to your friend!</li>
            </ol>
            <h4>Security</h4>
            <p>
              We use a <strong>strong Content-Security-Policy</strong> (<a href="https://developers.google.com/web/fundamentals/security/csp/" target="_blank" rel="noopener noreferrer">CSP</a>), which protects you from XSS attacks and makes sure that <span className="highlight">nobody can access the generated keys</span>, not even us! Your local browser is enforced to allow outgoing data only to the public EOS blockchain and your local Authenticator via the <i>connect-src</i> directive, as can be verified here: <a href="https://cspvalidator.org/#url=https://eosgiftcard.com" target="_blank" rel="noopener noreferrer">cspvalidator.org</a>
              <br/>
              <img src={imgTls} alt="TLS Encryption" style={{display:'block', margin:'15px auto'}}/>
              Additionaly, the PDF will be downloaded via a <span className="highlight">secure HTTPS connection</span>. Make sure to verify that you can see the small lock in the address bar of your browser!
            </p>
          </div>
          <div className="col-md-4 order-md-1">
            <a href="/jimmyparker1.pdf" target="_blank" rel="noopener noreferrer"><img src="/example.jpg" alt="Example" style={{maxWidth:'100%',border:'solid 1px grey'}}/><div className="text-center">Example PDF ...</div></a>
          </div>
        </div>
      </div>
    </section>

    <section id="pricing">
    <div className="container">
    <div className="row">
    <div className="col-md-8 offset-md-2 text-center">
      <h1>Pricing</h1>
      <p style={{fontSize:'1.2em'}}>Choose an appropriate EOS account name and package, which fits your needs. We have 3 plans available, from "Basic" to "Pro".</p>
        {
          this.props.account?
          <div>
            <div className="input-group input-group-lg mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text"><i className="fas fa-user"></i></span></div>
              <input type="text" pattern="[a-z1-5]{12}" maxLength={12} className="form-control" placeholder="e.g. jimmyparker1" value={this.state.accountName} onChange={this.checkAccount.bind(this)}/>
              <div className="input-group-append">
              <span className="input-group-text">{
                (this.state.accountAvailable === null)?
                <i className="fas fa-ellipsis-h"></i>
                :
                (this.state.accountAvailable === true)?
                <i className="fas fa-check" style={{color:'green'}}></i>
                :
                <i className="fas fa-times" style={{color:'red'}}></i>
              }</span></div>
            </div>
            {
              (this.state.accountAvailable === false || typeof this.state.accountAvailable === 'string')?
              <small style={{color:'red'}}>{this.state.accountAvailable || 'This account name is already taken, please choose another one.'}</small> :
              <div></div>
            }
            {
              (this.state.accountAvailable === true)?
              <small style={{color:'green'}}>Yay! This account name is still available, choose a plan below.</small> :
              <div></div>
            }
          </div>
          :
          <div className="text-center">
            <h1>
            <img src={anchor} alt="Anchor" className="rounded-circle" style={{height:50}}/> <img src={scatter} alt="Scatter" className="rounded-circle" style={{height:50}}/> <button className="btn btn-link" style={{lineHeight: 1.35, textAlign:'left'}} onClick={login}>Connect a wallet<br/>to buy a gift card</button>
            </h1>
          </div>
        }
    </div>

    <div className="container">
    <br/>
      <div className="card-deck mb-3 text-center">
        <div className="card mb-4 shadow-sm">
          <div className="card-header">
            <h4 className="my-0 font-weight-normal">Basic</h4>
          </div>
          <div className="card-body">
            <h1 className="card-title pricing-card-title" style={{marginBottom:0}}>2 <small className="text-muted">EOS</small></h1>
            {(!this.props.usdError)? <span style={{fontSize:'.85em'}} className="text-muted">– {this.props.usdLoading? '...' : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(2*this.props.eosUSD)} –</span> : null}
            <ul className="list-unstyled mt-3 mb-4">
              <li><b>3 KiB RAM</b> included</li>
              <li><b>0.2 EOS CPU</b> power</li>
              <li><b>0.1 EOS NET</b> bandwidth</li>
              <li><b>~{Math.floor((2-0.2-0.1-0.1-((3*1024+950)*this.state.priceKiB))*10)/10} EOS</b> available*</li>
            </ul>
            <button type="button" className="btn btn-lg btn-block btn-outline-primary" onClick={this.buyNow.bind(this, 2, '01')} disabled={!this.state.accountAvailable}>Buy Now</button>
            <small>Fee: 0.1 EOS</small>
          </div>
        </div>
        <div className="card mb-4 shadow-sm" id="testpdf">
          <div className="card-header">
            <h4 className="my-0 font-weight-normal">Standard</h4>
          </div>
          <div className="card-body">
            <h1 className="card-title pricing-card-title" style={{marginBottom:0}}>5 <small className="text-muted">EOS</small></h1>
            {(!this.props.usdError)? <span style={{fontSize:'.85em'}} className="text-muted">– {this.props.usdLoading? '...' : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(5*this.props.eosUSD)} –</span> : null}
            <ul className="list-unstyled mt-3 mb-4">
              <li><b>4 KiB RAM</b> included</li>
              <li><b>0.5 EOS CPU</b> power</li>
              <li><b>0.1 EOS NET</b> bandwidth</li>
              <li><b>~{Math.floor((5-0.5-0.1-0.2-((4*1024+950)*this.state.priceKiB))*10)/10} EOS</b> available*</li>
            </ul>
            <button type="button" className="btn btn-lg btn-block btn-primary" onClick={this.buyNow.bind(this, 5, '02')} disabled={!this.state.accountAvailable}>Buy Now</button>
            <small>Fee: 0.2 EOS</small>
          </div>
        </div>
        <div className="card mb-4 shadow-sm">
          <div className="card-header">
            <h4 className="my-0 font-weight-normal">Pro</h4>
          </div>
          <div className="card-body">
            <h1 className="card-title pricing-card-title" style={{marginBottom:0}}>10 <small className="text-muted">EOS</small></h1>
            {(!this.props.usdError)? <span style={{fontSize:'.85em'}} className="text-muted">– {this.props.usdLoading? '...' : new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(10*this.props.eosUSD)} –</span> : null}
              <ul className="list-unstyled mt-3 mb-4">
                <li><b>8 KiB RAM</b> included</li>
                <li><b>1.0 EOS CPU</b> power</li>
                <li><b>0.5 EOS NET</b> bandwidth</li>
                <li><b>~{Math.floor((10-1-0.5-0.3-((8*1024+950)*this.state.priceKiB))*10)/10} EOS</b> available*</li>
              </ul>
              <button type="button" className="btn btn-lg btn-block btn-primary" onClick={this.buyNow.bind(this, 10, '03')} disabled={!this.state.accountAvailable}>Buy Now</button>
              <small>Fee: 0.3 EOS</small>
          </div>
        </div>
        <small style={{display:'block',margin:'0 auto'}}>
          CPU & NET is delegated and can be <a href="https://bloks.io/account/cointreasury?loadContract=true&tab=Actions&account=cointreasury&scope=cointreasury&limit=100&action=claim" target="_blank" rel="noopener noreferrer">claimed/refunded</a> by the new user at any time.<br/>
          * Remaining liquid EOS balance depends on current RAM price.
        </small>
      </div></div>
      </div>
    </div></section>
    {modal}
    </div>)
  }
}

const mapStateToProps = (state: any /*, ownProps*/) => {
  const g = state.generic
  return { eosUSD: g.eosUSD, usdLoading: g.eosUSDLoading, usdError: g.eosUSDError }
}
const mapDispatchToProps = { fetchEOSUSD }
export default withUAL(connect(mapStateToProps, mapDispatchToProps)(Home))
export { Home as HomeTest }
