import React, {Component} from 'react'
import './Test.css'
import { generatePDF, dummyData, base64 } from '../pdfGenerate'

class Test extends Component {
  constructor () {
    super()
    this.state = {}
  }

  render() {
    return (
    <div className="Test">
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <h1>Testing Area</h1>
              <br/>
              <button onClick={generatePDF.bind(this, dummyData)}>Generate Dummy PDF ...</button>
              <br/><br/>
              <a href={base64.dataURL} download="base64-file.pdf">Download base64 PDF ...</a>
              <br/><br/>
              <a href="/jimmyparker1.pdf" download="jimmyparker1.pdf">Download Example PDF ...</a>
              <br/><br/>
              <pre>{window.navigator.userAgent}</pre>
            </div>
          </div>
        </div>
      </section>
    </div>
    )
  }
}

export default Test
