import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './Header.css'
import scatter from '../../img/scatter.png'
import anchor from '../../img/anchor.png'
import { withUAL } from 'ual-reactjs-renderer'

type HeaderProps = {
  ual: any,
  account?: { name: string, authority: string, scatter: boolean }
}

class Header extends Component<HeaderProps> {
  static contextTypes = {
    router: PropTypes.shape({
      history: PropTypes.shape({
        push: PropTypes.func.isRequired,
        replace: PropTypes.func.isRequired
      }).isRequired,
      staticContext: PropTypes.object
    }).isRequired
  }

  render() {
    const { ual: { logout, showModal: login } } = this.props
    const account = this.props.account
    const signInOutBtn = account?
      <span><span> &nbsp; </span><button onClick={logout} className="btn btn-outline-primary">Logout</button></span> :
      <span><span> &nbsp; </span><button onClick={login} className="btn btn-outline-primary">Sign In</button></span>
    return <div className="Header">
      <div className="row no-gutters align-items-center">
        <div className="col-md-2 order-md-1 col-8 order-1 text-left">
          <div className="p-2 pl-xl-5">
            <a href="/"><img src="/logo-text.png" alt="EOS Gift Card" style={{height:58}}/></a>
          </div>
        </div>
        <div className="col-4 order-2 d-md-none text-right">
          <div className="p-2">
            {signInOutBtn}
          </div>
        </div>
        <div className="col-md-10 order-md-2 col-12 order-3 text-md-right text-center">
          <nav className="p-2 pr-xl-5">
            <span className="d-none d-md-inline">{account?<span><img src={account.scatter?scatter:anchor} alt="Authenticator" className="rounded-circle" style={{height:38}}/> {account.name}<span className="text-muted">@{account.authority}</span> &nbsp; </span> : <span></span>}</span>
            <a className="p-2 text-dark" href="#about">About</a>
            <span> &nbsp; </span>
            <a className="p-2 text-dark" href="#pricing">Pricing</a>
            <span className="d-md-none">
            {
              account?
              <span>
                <span> &nbsp; </span>
                {account.name} <img src={account.scatter?scatter:anchor} alt="Authenticator" className="rounded-circle" style={{height:17}}/>
              </span>
              :
              <span></span>
            }
            </span>
            <span className="d-none d-md-inline">{signInOutBtn}</span>
          </nav>
        </div>
      </div>
    </div>
  }
}

export default withUAL(Header)
